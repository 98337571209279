.Navbar .NavbarItem {
  padding: 16px;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
}

/* Fist child should, in fact, have left padding
.NavbarItem:first-child {
  margin-left: 0px;
}
//*/

/* Change the color of links on hover */
.Navbar .NavbarItem:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.Navbar .NavbarItem.Active {
  background-color: #f55252d9; /* Green: #4CAF50; */
  color: white;
}

/* Links will not be underlined */
.Navbar .NavbarItemText {
  text-decoration: none;
  color: #f2f2f2;
}

/* Links will not be underlined */
.Navbar .NavbarItemText.Active {
}

@media only screen and (max-width: 600px) {
  .Navbar .NavbarItemText {
    flex: 1 1 auto;
    height: 100%;
  }

  .Navbar .NavbarItem {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    min-width: 40px;

    /* Required for multi-line Navbar Items. e.g. "Survey Methodology"
    /* min-height: 40px;  */  }
}
