
.FooterRoot {
  background: #2f2f2f;
  color: white;
}


.Section.Footer .SectionContent {
  padding-left: 0;
}

.Footer .FooterRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer .FooterRow .FooterData {
  flex: 0 0 auto;
}

.Footer .FooterRow .FooterLinks {
  flex: 0 0 auto;
}

.FooterDataSection {
  padding-top: 20px;
}

.FooterDataSection:first-child {
  padding-top: 0px;
}

.FooterDataSection .FooterDataSectionLabel {
  font-weight: bold;
}

.FooterDataSection .FooterDataSectionText {
  padding-top: 4px;
  font-size: 16px;
  font-weight: 200;
}

.FooterDataSection .FooterDataSectionText:first-child {
  padding-top: 4px;
}

@media only screen and (max-width: 800px) {
  .Footer .FooterRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8px;
  }

  .Footer .FooterRow .FooterData {
    order: 2;
    padding-top: 20px;
  }

  .Footer .FooterRow .FooterLinks {
    order: 1;
  }
}
