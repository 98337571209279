
.ServiceRow {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.ServiceRow:first-child {
  margin-top: 0px;
}

.ServiceRow .ServiceRowIcon {
  flex: 0 0 20px;
}

.ServiceRow .ServiceRowName {
  margin-left: 4px;
  flex: 0 0 250px;
}

.ServiceRow .ServiceRowName .PublicationConferenceName {
  font-weight: 400;
}

.ServiceRow .ServiceRowPosition {
  margin-left: 20px;
  flex: 1 0 auto;
  align-items: flex-start;
}

@media only screen and (max-width: 500px) {
  .ServiceRow {
    flex-direction: column;
  }

  .ServiceRow .ServiceRowIcon {
    display: none;
  }

  .ServiceRow .ServiceRowName {
    flex: 1 0 auto;
  }

  .ServiceRow .ServiceRowPosition {
    padding-left: 20px;

  }
}
