/* For markdown */
.PublicationMainColumn {
}

.PublicationMainColumn p {
  margin: 0;
}

.PublicationMainColumn .Glyph {
  color: #9c0e0ebd;
}

.PublicationMainColumn .PublicationTitle {
  font-size: 18px;
  font-weight: 500;
}

.PublicationSecondaryRows {
  color: #777777;
  font-size: 14px;
  font-weight: normal;
}


@media only screen and (max-width: 800px) {
  .PublicationMainColumn .PublicationTitle {
    font-size: 16px;
  }

  .PublicationSecondaryRows {
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .PublicationSecondaryRows > div:nth-child(odd) {
    background-color: rgba(245, 82, 82, 0.08);
  }
}
