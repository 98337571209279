
.IntimacyHeader {
  margin-left: 10%;
  margin-right: 20px;
  margin-bottom: 80px;
  margin-top: 80px;
  text-align: left;
  font-size: 24px;
}

.IntimacyHeader .Glyph {
  color: #b31a1a;
}

.IntimacyHeader .Glyph:hover {
  color: #f55252d9
}

.IntimacyHeader .IntimacyText {
  margin-top: -16px;
  margin-bottom: 20px;


  font-weight: 500;
  font-size: 12px;
}

.Intimacy .Project {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Intimacy .Project .ProjectRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  padding: 12px;
}

.Intimacy .Project .ProjectRow:hover {
  background-color: rgba(245, 82, 82, 0.08);
}

.Intimacy .Project .ProjectRow .ProjectGlyph {
  flex: 0 0 20px;
  margin-right: 8px;
}

.Intimacy .Project .ProjectRow .ProjectTitle {
  flex: 1 1 auto;

  font-weight: 500;
  font-size: 16px;
}


.Intimacy .Project .ProjectBody {
  margin-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #777777;
}
