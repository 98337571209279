
.PublicationMedia {
  line-height: normal;
}

.PublicationMedia .PublicationMediaPublisher {
  width: fit-content;
  margin-right: 12px;
  font-weight: bold;
}

.PublicationMedia .PublicationMediaTitle {
  width: fit-content;
  font-size: smaller;
}
