.PublicationSecondaryRow {
  display: flex;
  margin-top: 4px;
}

.PublicationSecondaryRow .PublicationGlyph {
  flex: 0 0 20px;
  padding-right: 4px;
}

.PublicationSecondaryRow .PubliationContent {
  flex: 1 1 calc(100% - 24px);
}

.PublicationMainColumn .PublicationSecondaryRow .PublicationGlyph .Glyph {
  color: #f55252d9;
}

.PublicationMainColumn .PublicationSecondaryRow:nth_child(even) .PublicationGlyph .Glyph {
  color: #9c0e0ebd;
}
