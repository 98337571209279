
.ServiceCard {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 500px) {
  .ServiceCard .Section:not(:last-child) {
    padding-bottom: 0px;
  }
}
