.Section {
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 15%;
  padding-right: 15%;
  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
}

.Section.SectionDisplayIframe {
  max-width: 70%;
}

.Section:first-child {
  /* padding-top: 0px; */
}

/* TODO:  If page smaller than X, change display */
/* TODO:  If page larger than X, at most Y pixels */
.Section .SectionLabel {
  flex: 0 0 auto;
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
  width: 100%;
}

.Section.SectionHideLabel .SectionLabel {
  display: none;
}

.Section .SectionLabel .SectionLabelText {
  text-transform: uppercase;
  font-weight: 500; /* same as bold */
  font-size: 1.5em;
  text-shadow: 1px 1px 1px rgba(0, 0, .1, .2);
  color: #9c0e0ebd;
}

.Section .SectionContent {
  padding-left: 40px;
  flex: 1 0 auto;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 800px) {
  .Section {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;

    display: flex;
    flex-direction: column;
  }

  .Section.SectionDisplayIframe {
    max-width: 90%;
  }

  .Section .SectionLabel {
    flex: 0 0 auto;
    justify-content: flex-start;
  }

  .Section .SectionLabel .SectionLabelText {
    font-weight: 900;
    font-size: 18px;
    /*
    border-bottom: 4px ridge #4d1a1a;
    border-bottom: 4px ridge darkred;
    border-bottom: 4px ridge indianred;
    //*/

    width: 100%; /* width of bottom border */
    text-align: left;
  }

  .Section .SectionContent {
    flex: 1 0 auto;
    width: 100%;
    position: relative;

    padding-left: 0px;
  }
}
