
.PublicationConference {
  display: flex;
  flex-direction: column;
}

.PublicationConferenceName,
.PublicationConference .PublicationConferenceName {
  font-weight: 700; /* Override above font-weight */
}

.PublicationConferenceYear,
.PublicationConference .PublicationConferenceYear {
  margin-left: 4px;
}

/* Stack on mobile */
.PublicationConference .PublicationConferenceLightText.PublicationSecondaryRows,
.PublicationConference .PublicationConferenceLightText {
  font-weight: 400;
  font-weight: 14px;
}

.PublicationConference.PublicationConferenceMobileOnly {
  display: none;
}

@media only screen and (max-width: 800px) {
  .PublicationConference {
    flex-direction: row;
  }

  .PublicationConference.PublicationConferenceMobileOnly {
    display: flex;
  }
}
