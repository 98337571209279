
.PublicationBestPaperRunnerUp {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}

.PublicationBestPaperRunnerUp .PublicationBestPaperRunnerUpTrophy {
  flex: 0 0 50px;
  font-size: 30px;
}

.PublicationBestPaperRunnerUp .PublicationBestPaperRunnerUpTrophy .Glyph {
  color: #9c0e0ebd;
}

.PublicationBestPaperRunnerUp .PublicationBestPaperRunnerUpText {
  flex: 1 0 auto;
  max-width: calc(100% - 50px);
  font-weight: 500;
  color: #9c0e0ebd;
}
