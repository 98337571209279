
.Glyph {
  color: #f55252d9; /* Old purplish color is "#776282" */
  textDecoration: none;
  padding-left: 8px;
}

.Glyph:first-child {
  padding-left: 0px;
}

.Glyph:hover {
  color: #b31a1a;
}

.Glyph.Hidden {
  visibility: hidden;
}
