.Navbar {
  display: flex;
  list-style-type: none;
  padding-left: 20px;
  background-color: #333;
}

@media only screen and (max-width: 600px) {
  .Navbar {
    padding-left: 0px;
    justify-content: space-evenly;
  }
}
