
.YoutubeVideo {
  position: relative;
  position: relative;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}

.YoutubeVideo.sixteen-nine {
  padding-bottom: 58%; /* Height / Width = 0.5625 */
}

.YoutubeVideo.four-three {
  padding-bottom: 77%; /* Height / Width = 0.75 */
}

.YoutubeVideo iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
