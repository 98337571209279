
.CVHeader {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
  text-align: right;
  font-size: 20px;
}

.CVHeader .Glyph {
  color: #b31a1a;
}

.CVHeader .Glyph:hover {
  color: #f55252d9
}
