.Name {
  background: #000;
  background: url(../images/footer_lodyas.png) no-repeat center top;
  text-align:center;
  background-attachment: relative;
  background-position: center center;
  min-height: 500px;
  height: auto%;
  width: 90%;
  max-width: 90%;
  padding-right: 5%;
  padding-left: 5%;

  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;

}

.Name .NameHeadshot {
  flex: 1 1 30%;
  justify-content: center;
  align-items: center;

  display: flex;
  max-width: 350px;
}

.Name .NameVerticallyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.NameHeadshotImage {
  max-height: 400px;
  width: auto;
  max-width: 100%;
}

.Name .NameContent {
  flex: 1 1 70%;

  display: flex;
  justify-content: flex-start;
  max-width: 615px;
}

.Name .NameContent .NameContentWrapper {
  /* background: black; */
  color: white;
  padding: 40px;
}

.Name .NameContent .NameContentWrapper .NameContentFullName  {
  font-family: "Raleway";
  font-size: 2em;
  font-weight: 800;
}

.Name .NameContent .NameContentWrapper .NameContentTitle h3 {
  font-size: 1.5em;
  font-weight: 600;
}


@media only screen and (max-width: 975px) {
  .Name .NameContent .NameContentWrapper .NameContentFullName h1 {
    font-size: 1.5em;
  }

  .Name .NameContent .NameContentWrapper .NameContentTitle h3 {
    font-size: 1.25em;
  }
}


@media only screen and (max-width: 800px) {
  .Name .NameContent .NameContentWrapper .NameContentFullName h1 {
    font-size: 1.25em;
    font-weight: 700;
  }

  .Name .NameContent .NameContentWrapper .NameContentTitle h3 {
    font-size: 1.0em;
  }
}

@media only screen and (max-width: 650px) {
  .Name {
    padding-top: 40px;
    flex-direction: column
  }

  .Name .NameContent .NameContentWrapper .NameContentFullName h1 {
    font-size: .9em;
  }

  .Name .NameContent .NameContentWrapper .NameContentTitle h3 {
    font-size: .8em;
  }
}
