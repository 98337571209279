.About {
}

.About .AboutContent {
}

.About .AboutContent .AboutText {
  overflow: auto;

  font-size: 16px;
  font-weight: 300;
  white-space: pre-wrap;
}
