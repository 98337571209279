
.CovidHeader {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
  text-align: right;
  font-size: 20px;
}

.CovidHeader .Glyph {
  color: #b31a1a;
}

.CovidHeader .Glyph:hover {
  color: #f55252d9
}

.Covid .CovidText {
  margin-top: -16px;
  margin-bottom: 20px;


  font-weight: 500;
  font-size: 12px;
}

.Covid .Project {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Covid .Project .ProjectRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  padding: 12px;
}

.Covid .Project .ProjectRow:hover {
  background-color: rgba(245, 82, 82, 0.08);
}

.Covid .Project .ProjectRow .ProjectGlyph {
  flex: 0 0 20px;
  margin-right: 8px;
}

.Covid .Project .ProjectRow .ProjectTitle {
  flex: 1 1 auto;

  font-weight: 500;
  font-size: 16px;
}


.Covid .Project .ProjectBody {
  margin-left: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #777777;
}
