
.PublicationWatchPresentation {
  flex: 0 0 40%;

  /* TODO:  Figure out what we is and is not required */
  margin: 4px;
  color: red;
  overflow: hidden;
  position: relative;
  mix-blend-mode: hard-light;
  justify-content: center;
  max-width: 90%;
  max-height: 150px;

  /* TODO:  Figure out what we is and is not required */
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow-y: hidden;
  width: 40%;
  max-height: 75px;
}

.PublicationWatchPresentation:after {
  /* TODO:  Figure out what we is and is not required */
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  height: calc(100% + 4px);
  width: 100%;
  background: linear-gradient(transparent, rgb(242,242,242));
}

.PublicationWatchPresentation.PublicationWatchPresentationBorder {
  margin: 0px;
  border-width: 4px;
  border-style: solid;
  border-color: #9c0e0ebd;
}

.PublicationWatchPresentation.PublicationWatchPresentationBorder:after {
  background: linear-gradient(transparent, rgb(0, 0, 0, 0));
}

.PublicationPresentation {
  display: flex;
}

.PublicationPresentation .PublicationWatchPresentation {
  flex: 0 0 40%;
}

.PublicationPresentation .PublicationPresentationVideoText {
  margin-left: 8px;
  padding: 4px;

  background-color: white;
  border-width: 4px;
  border-style: solid;
  border-color: #9c0e0ebd;

  font-weight: 900;
  font-size: larger;

  /* Ensure content huggs left post */
  align-self: center;
  flex: 0 1 auto; /* not sticly necessary */
  width: min-content;
}
