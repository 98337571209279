
.ServiceDiversityAndInclusion li {
  margin-top: 12px;
}

.ServiceDiversityAndInclusion li:first-child {
  margin-top: 0px;
}

.ServiceDiversityAndInclusion li li {
  margin-top: 4px;
}

.ServiceDiversityAndInclusion li li:first-child {
  margin-top: 0px;
}

.ServiceDiversityAndInclusion strong, .ServiceDiversityAndInclusion b {
  font-weight: 400;
}
