
.CVPageViewer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.CVPageViewer:first-child {
  margin-top: 0px;
}

.CVPageViewer .CVPageViewerPage {
  max-width: 100%;
  overflow-x: scroll;
}
