.Publication {
  display: flex;
  flex-direction: row;
  max-width: 1400px;
  margin-top: 20px;
}

.Publication:first-child {
  margin-top: 0px;
}

.Publication .PublicationNumber {
  flex: 0 0 20px;
}

.Publication .PublicationCenterColumn {
  flex: 1 1 60%;
  min-width: 50%;
  max-width: 70%;
  margin-left: 20px;
}

.Publication .PublicationRightColumn {
  flex: 0 0 170px;
  margin-left: 40px;
}

@media only screen and (max-width: 800px) {
  .Publication .PublicationCenterColumn {
    flex: 1 1 calc(100% - 40px); /* margin + number */
    margin-left: 20px;
    max-width: 95%;
    max-width: calc(100% - 40px);
  }
}
