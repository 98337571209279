
.CVDocumentViewer {
  max-height: 90vh;
  overflow-y: scroll;
  padding: 20px;
  background-color: rgb(82, 86, 89);
}

@media only screen and (max-width: 500px) {
  .CVDocumentViewer {
    max-height: 78vh;
  }
}
