
.Award {
  margin-top: 10px;
}

.Award:first-child {
  margin-top: 0px;
}

.Award .AwardTitle {
}

.Award .AwardTitle .AwardTitleLink {
  font-size: 9px;
  margin-left: 4px;
  display: inline;
}

.Award .AwardTitle .AwardTitleText {
  font-size: 16px;
  font-weight: 400;
  display: inline;
}

.Award .AwardDetails {
  font-size: 14px;
  font-weight: 300;

  margin-left: 20px;
}
