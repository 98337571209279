body {
  margin: 0;
  padding: 0;
  font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";

  font-weight: 300;
  font-size: 16px;
  color: #555;

  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background-color: #f2f2f2;
}
